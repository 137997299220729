export default {
	game18: () => import(/* webpackChunkName: "game-18" */ '../data/game-18.json'),
	game19: () => import(/* webpackChunkName: "game-19" */ '../data/game-19.json'),
	game20: () => import(/* webpackChunkName: "game-20" */ '../data/game-20.json'),
	game21: () => import(/* webpackChunkName: "game-21" */ '../data/game-21.json'),
	game22: () => import(/* webpackChunkName: "game-22" */ '../data/game-22.json'),
	game23: () => import(/* webpackChunkName: "game-23" */ '../data/game-23.json'),
	game24: () => import(/* webpackChunkName: "game-24" */ '../data/game-24.json'),
	game25: () => import(/* webpackChunkName: "game-25" */ '../data/game-25.json'),
	game26: () => import(/* webpackChunkName: "game-26" */ '../data/game-26.json'),
	game27: () => import(/* webpackChunkName: "game-27" */ '../data/game-27.json'),
	game28: () => import(/* webpackChunkName: "game-28" */ '../data/game-28.json'),
	game29: () => import(/* webpackChunkName: "game-29" */ '../data/game-29.json'),
	game30: () => import(/* webpackChunkName: "game-30" */ '../data/game-30.json'),
	game31: () => import(/* webpackChunkName: "game-31" */ '../data/game-31.json'),
	game32: () => import(/* webpackChunkName: "game-32" */ '../data/game-32.json'),
	game33: () => import(/* webpackChunkName: "game-33" */ '../data/game-33.json'),
	game34: () => import(/* webpackChunkName: "game-34" */ '../data/game-34.json'),
	game35: () => import(/* webpackChunkName: "game-35" */ '../data/game-35.json'),
	game36: () => import(/* webpackChunkName: "game-36" */ '../data/game-36.json'),
	game37: () => import(/* webpackChunkName: "game-37" */ '../data/game-37.json'),
	game38: () => import(/* webpackChunkName: "game-38" */ '../data/game-38.json'),
	game39: () => import(/* webpackChunkName: "game-39" */ '../data/game-39.json'),
	game40: () => import(/* webpackChunkName: "game-40" */ '../data/game-40.json'),
	game41: () => import(/* webpackChunkName: "game-41" */ '../data/game-41.json'),
	game42: () => import(/* webpackChunkName: "game-42" */ '../data/game-42.json'),
	game43: () => import(/* webpackChunkName: "game-43" */ '../data/game-43.json'),
	game44: () => import(/* webpackChunkName: "game-44" */ '../data/game-44.json'),
	game45: () => import(/* webpackChunkName: "game-45" */ '../data/game-45.json'),
	game46: () => import(/* webpackChunkName: "game-46" */ '../data/game-46.json'),
	game47: () => import(/* webpackChunkName: "game-47" */ '../data/game-47.json'),
	game48: () => import(/* webpackChunkName: "game-48" */ '../data/game-48.json'),
	game49: () => import(/* webpackChunkName: "game-49" */ '../data/game-49.json'),
	game50: () => import(/* webpackChunkName: "game-50" */ '../data/game-50.json'),
	game51: () => import(/* webpackChunkName: "game-51" */ '../data/game-51.json'),
	game52: () => import(/* webpackChunkName: "game-52" */ '../data/game-52.json'),
	game53: () => import(/* webpackChunkName: "game-53" */ '../data/game-53.json'),
	game54: () => import(/* webpackChunkName: "game-54" */ '../data/game-54.json'),
	game55: () => import(/* webpackChunkName: "game-55" */ '../data/game-55.json'),
	game56: () => import(/* webpackChunkName: "game-56" */ '../data/game-56.json'),
	game57: () => import(/* webpackChunkName: "game-57" */ '../data/game-57.json'),
	game58: () => import(/* webpackChunkName: "game-58" */ '../data/game-58.json'),
	game59: () => import(/* webpackChunkName: "game-59" */ '../data/game-59.json'),
	game60: () => import(/* webpackChunkName: "game-60" */ '../data/game-60.json'),
	game61: () => import(/* webpackChunkName: "game-61" */ '../data/game-61.json'),
	game62: () => import(/* webpackChunkName: "game-62" */ '../data/game-62.json'),
	game63: () => import(/* webpackChunkName: "game-63" */ '../data/game-63.json'),
	game64: () => import(/* webpackChunkName: "game-64" */ '../data/game-64.json'),
	game65: () => import(/* webpackChunkName: "game-65" */ '../data/game-65.json'),
	game66: () => import(/* webpackChunkName: "game-66" */ '../data/game-66.json'),
	game67: () => import(/* webpackChunkName: "game-67" */ '../data/game-67.json'),
	game68: () => import(/* webpackChunkName: "game-68" */ '../data/game-68.json'),
	game69: () => import(/* webpackChunkName: "game-69" */ '../data/game-69.json'),
}